.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loginCard {
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.ant-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}