
/* Banned Trade Request */
.bannedTradeRequestWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.bannedTradeRequestGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.breadcrumbWrapper {
  margin-top: 10px;
  margin-left: 10px;
}

.accountHoldingOperationBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  margin-top: 10px;
}

.accountHoldingOperationBar button {
  /* margin-left: 10px; */
  font-size: 10px;
}

.accountHoldingOperationBar .ant-divider, .ant-divider-vertical {
  margin: 0 10px !important;
  background-color: darkgray;
}

/* Personal Trade */
.personalTradeWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.personalTradeGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  margin-top: 2px;
}


/* Personal Holding */
.personalHoldingWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.personalHoldingGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}


/* Personal Account */
.personalAccountWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.personalAccountGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

/* Gift Hospitality Request */
.giftHospitalityRequestWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.giftHospitalityRequestGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.accountHoldingReportPlanGrid {
  height: 100%;
  width: 300px;
  min-width: 300px;
}

.personalAccountGridWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.ag-theme-balham .UnProcessedReport {
  background-color: lightgreen !important;
}

.ag-theme-balham .not-upload-proof {
  background-color: red !important;
}

.all-employee-monthly-report tbody {
  color:black;
  height:100px;
}

.all-employee-monthly-report tbody td {
  padding-left: 10px;
  padding-right: 10px;
}

.each-employee-monthly-report thead th {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.each-employee-monthly-report tbody {
  color:black;
  height:100px;
}

.each-employee-monthly-report tbody td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.daily-all-employee-monthly-report {
  margin-top: 10px;
}

.daily-all-employee-monthly-report thead th {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.daily-all-employee-monthly-report tbody td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
