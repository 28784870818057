
/* Short Position Report */
.shortPositionReportWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
  /* background-color: aquamarine; */
}

.shortPositionReportGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.breadcrumbWrapper {
  margin-top: 10px;
  margin-left: 10px;
}

.operationBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  margin-top: 10px;
}

.operationBar button {
  margin-left: 10px;
  font-size: 10px;
}


/* Fund Leve Report */
.fundLevelReportWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
  /* background-color: aquamarine; */
}

.fundLevelReportGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.thirteenFLongPosReportWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.thirteenFLongPosReportGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}