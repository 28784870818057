
.LicensingWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    width: 100%;
}

.breadcrumbWrapper {
    margin-top: 10px;
    margin-left: 10px;
}

.LicensingOperationBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    margin-top: 10px;
}

.LicensingOperationBar button {
  font-size: 10px;
}

.LicensingOperationBar .ant-divider, .ant-divider-vertical {
  margin: 0 10px !important;
  background-color: darkgray;
}

.LicensingGrid {
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

.near-deadline {
    background-color: #FFFF77;
}
.exceed-deadline {
    background-color: #FF0000;
}

.ag-theme-balham .lastUpdatedByReport {
    background-color: lightgreen !important;
}