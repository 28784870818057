
/* Fund */
.fundWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.fundGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

/* Book */
.bookWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.bookGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

/* Custodian */
.custodianWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.breadcrumbWrapper {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.operationBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  margin-top: 10px;
}

.operationBar button {
  margin-left: 10px;
  font-size: 10px;
}



/* Personal Trade */
.personalTradeWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.personalTradeGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  margin-top: 2px;
}


/* Personal Holding */
.personalHoldingWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.personalHoldingGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}


/* Personal Account */
.personalAccountWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.personalAccountGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

/* Gift Hospitality Request */
.giftHospitalityRequestWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.giftHospitalityRequestGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.accountHoldingReportPlanGrid {
  height: 100%;
  width: 300px;
  min-width: 300px;
}

.personalAccountGridWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}