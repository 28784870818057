.questionnaireDashboardWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
  padding-bottom: 20px;
}

.questionnaireDashboardContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  min-width: 320px;
  padding: 20px;
}

.title {
  font-size: 20px;
  margin-top: 20px;
  color: #1ea0fa;
}

.desc {
  font-size: 16px;
  margin-top: 10px;
}

.content {
  margin-top: 20px;
  height: 100%;
  width: 100%;
  border-top: 1px dashed #c2c2c2;
}

.questionContainer {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 10px;
}

.questionDescContainer {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.question-desc-index {
  font-size: 16px;
  font-weight: bold;
}

.question-desc {
  font-size: 16px;
  min-width: 200px;
  word-break: normal;
  margin-left: 5px;
  margin-right: 20px;
  font-weight: bold;
}

.buttons {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 10px;
  width: 50%;
  min-width: 220px;
  height: 40px;
  max-height: 40px;
}

.emptyQuestions {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.emptyQuestionsText {
  margin-top: 20px;
  font-size: 18px;
  text-align: center;
}

.answerContainer {
  margin-left: 20px;
  margin-top: 10px;
}

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
  width: 250px;
  word-break: normal;
}

.checkStyle {
  display: block;
  height: 30px;
  line-height: 30px;
  margin-left: 0px !important;
}

.topic__type-des {
  font-size: 16px;
  color: #666;
  height: 10px;
  background-color: #1ea0fa;
  text-indent: 25px;
  line-height: 24px;
}

.topic__dottedLine {
  border-top: 10px dashed red;
}

/* 任务内容 */
.question-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* 子任务内容 */
.sub-question-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sub-question-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.sub-question-desc-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.sub-question-desc-number {
  font-size: 16px;
}

.sub-question-desc {
  font-size: 16px;
  min-width: 200px;
  word-break: normal;
  margin-left: 5px;
  margin-right: 20px;
}

/* 签名 */
.signature-container {
  min-width: 220px;
  margin-top: 30px;
  margin-bottom: 30px;
}