
/* Restricted Security */
.restrictedSecurityWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
  /* background-color: aquamarine; */
}

.restrictedSecurityGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.radioGroupWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.breadcrumbWrapper {
  margin-top: 10px;
  margin-left: 10px;
}

.operationBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  margin-top: 10px;
}

.operationBar button {
  margin-left: 10px;
  font-size: 10px;
}



/* PB Short Interest Rate */
.pbShortInterestRateWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.pbShortInterestRateGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  margin-top: 2px;
}


/* Restricted Asset */
.restrictedAssetWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.restrictedAssetGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}


/* Restricted Country */
.restrictedCountryWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.restrictedCountryGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

/* Restricted Sector */
.restrictedSectorWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.restrictedSectorGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}




/* SFC Shortable List */
.sfcShortableListWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;
}

.sfcShortableListGrid {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  margin-top: 2px;
}

.ag-theme-balham .UnProcessedReport {
  background-color: lightgreen !important;
}


.ag-theme-balham .MarkBackgroundGreen {
  background-color: #99FF99 !important;
}
.ag-theme-balham .MarkBackgroundRed {
  background-color: #FF8888 !important;
}
.ag-theme-balham .MarkBackgroundGray {
  background-color: lightgray !important;
}
.ag-theme-balham .MarkBackgroundYellow {
  background-color: yellow !important;
}
.ag-theme-balham .MarkBackgroundOr {
  background-color: #ffbb00 !important;
}
.ag-theme-balham .MarkBackgroundRede {
  background-color: #f80b0b !important;
}

