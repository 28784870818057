.OperationBar button {
    font-size: 10px;
}

.CptWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    width: 100%;
}

.breadcrumbWrapper {
    margin-top: 10px;
    margin-left: 10px;
}

.OperationBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    margin-top: 10px;
}

.CptGrid {
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}